import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput } from "@wafo/forms";
import { useState } from "react";
import { useLocation } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";

const FormWorker = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();

  const { state: locationState } = useLocation();

  const [worker] = useState(() => {
    if (locationState && locationState.worker) {
      return locationState.worker;
    }
    return {};
  });

  const onSubmit = async (form, values) => {
    if (!form.valid) return;
    loader.set();
    try {
      if (worker?.id) {
        await api.put("/cat/trab", { ...values }, { params: { id: worker.id } });
      } else {
        await api.post("/cat/trab", { ...values });
      }
      onAfterSubmit();
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  return (
    <WafoForm formId="form-worker" locale="es" onSubmit={onSubmit} values={worker} ignoreEmpty>
      <WafoFormInput
        type="text"
        name="nombre"
        label="Nombre de actividad"
        placeholder="Ingrese nombre"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <div className="col-12 mt-3 text-center">
        <button type="submit" form="form-worker" className="btn btn-primary">
          <FontAwesomeIcon icon={faSave} className="fa-icon" />
          <span>Guardar cambios</span>
        </button>
      </div>
    </WafoForm>
  );
};

export default FormWorker;
