import { Redirect, Switch, Route, BrowserRouter } from "react-router-dom";
import Auth from "scenes/auth/auth";
import Panel from "scenes/panel";

const RouterBase = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/auth" />
        <Route path="/auth" component={Auth} />
        <Route path="/panel" component={Panel} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterBase;
