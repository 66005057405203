import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const CultivosTarjeta = props => {
  const { cultivos } = props;
  return (
    <div className="card separate">
      <div className="card-body">
        <div className="">
          <h5>
            <FontAwesomeIcon icon={faIdCard} className="fa-icon" /> Cultivos
          </h5>
        </div>
        <hr />
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre de cultivo</th>
              </tr>
            </thead>
            <tbody>
              {cultivos.length > 0 ? (
                cultivos.map(cultivo => (
                  <tr key={cultivo.id_cultivo}>
                    <td>{cultivo.id_cultivo}</td>
                    <td>{cultivo.nombre_cultivo}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Sin cultivo definido</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

CultivosTarjeta.propTypes = {
  cultivos: PropTypes.array,
};

CultivosTarjeta.defaultProps = {
  cultivos: [],
};

export default CultivosTarjeta;
