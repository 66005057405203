import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useHistory } from "react-router-dom";
import logo from "assets/logo-default.png";
import { useUI } from "utils/context/uiContext";
import React from "react";


const Sidebar = () => {

  function logout() {
    localStorage.clear();
  }

  const history = useHistory();


  const [{ toggleSidebar }, { sidebar, menuLinks }] = useUI();

  return (
    <div className={`sidebar ${sidebar && "collapsed"}`} data-testid="sidebar">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <p className="title">SCA - Webpanel</p>
      </div>
      <div className="body">
        <ul className="links">
          {menuLinks.map(link => (
            <React.Fragment>
              <li key={link.id}>
                <NavLink to={link.path} activeClassName="active">
                  <FontAwesomeIcon className="fa-icon" icon={link.icon} />
                  <span>{link.title}</span>
                </NavLink>
              </li>
            </React.Fragment>
          ))}
              <li onClick={logout}>
                <a href="/" title={'Cerrar sesión'}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="fa-icon"/> Cerrar sesión
                </a>
              </li>
        </ul>
      </div>
      <div className="footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          title="Toggle sidebar"
          onClick={() => toggleSidebar()}
        >
          <FontAwesomeIcon className="" icon={faBars} />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
