import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput, WafoFormSelect } from "@wafo/forms";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";

const FormAppointer = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();

  const { state: locationState } = useLocation();

  
  const [trabajador, setTrabajadores] = useState([]);

  const [appointer] = useState(() => {
    if (locationState && locationState.appointer) {
      return locationState.appointer;
    }
    return {};
  });

  // Load types
  useEffect(() => {
    loader.set();
    api
      .get("/cat/trab/list")
      .then(resp => {
        setTrabajadores(
          resp.rows.map(x => ({
            value: x.id,
            display: x.nombre,
          })),
        );
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, loader]);


  const onSubmit = async (form, values) => {
    debugger;
    if (!form.valid) return;
    loader.set();
    try {
      if (appointer?.id) {
        await api.put("/user/apuntador", { ...values }, { params: { id: appointer.id } });
      } else {
        await api.post("/user/apuntador", { ...values });
      }
      onAfterSubmit();
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  return (
    <WafoForm formId="form-appointer" locale="es" onSubmit={onSubmit} values={appointer} ignoreEmpty>
      <WafoFormInput
        type="text"
        name="username"
        label="Nombre de usuario"
        placeholder="Ingrese nombre de usuario"
        customClass="col-12 col-md-3"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormInput
        type="text"
        name="name"
        label="Nombre (completo)"
        placeholder="Ingrese nombre de apuntador"
        customClass="col-12 col-md-3"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormSelect
        name="id_trabajador"
        label="Trabajador"
        defaultValue="Seleccione el trabajador"
        options={trabajador}
        customClass="col-12 col-md-3"
        validations={{ required: false }}
      />

      <WafoFormInput
        type="password"
        name="password"
        label="Contraseña"
        placeholder="Ingrese contraseña"
        customClass="col-12 col-md-3"
        validations={{ required: !appointer?.id, maxLength: 100, minLength: 6 }}
      >
        {appointer?.id && <p className="extra">Dejar vacio para conservar contraseña actual</p>}
      </WafoFormInput>

      <div className="col-12 mt-3 text-center">
        <button type="submit" form="form-appointer" className="btn btn-primary">
          <FontAwesomeIcon icon={faSave} className="fa-icon" />
          <span>Guardar cambios</span>
        </button>
      </div>
    </WafoForm>
  );
};

export default FormAppointer;
