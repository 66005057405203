import FormLogin from "components/forms/auth/formLogin";
import { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();

  return (
    <Fragment>
      <p className="description">Bienvenido, inicia sesión</p>
      <FormLogin onAfterSubmit={() => history.push("/panel")} />
      {/*<ul className="links">
        <li>
          <Link to="/">Registrarse</Link>
        </li>
        <li>
          <Link to="/">Recuperar contraseña</Link>
        </li>
        </ul>*/}
    </Fragment>
  );
};

export default Login;
