import { NavLink } from "react-router-dom";

/**
 *
 * @param {[]} buttons
 * Example: [{ path: "", title: "" }]
 * @returns null
 */
const NavButttons = ({ buttons = [] }) => {
  return (
    <div className="btn-group mb-3" role="group" aria-label="Menú de sección">
      {buttons.map((button, i) => (
        <NavLink key={i} to={button.path} className="btn btn-outline-secondary" activeClassName="active">
          {button.title}
        </NavLink>
      ))}
    </div>
  );
};

export default NavButttons;
