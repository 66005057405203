import React from 'react';
import useApi from "utils/hooks/useApi";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faPlus } from "@fortawesome/free-solid-svg-icons";
import "../style-tarjetaTrabajo.scss";

const ComentariosTarjeta = (props) => {
    const api = useApi();
    const { id, NotificationManager, editable } = props;
    const [newComment, setNewComment] = React.useState('');
    const [comments, setComments] = React.useState({ count: 0, rows: [] });


    React.useEffect(() => {
        apiGetComments(id)
            .then(response => {
                if (response.error) {

                } else {
                    setComments(response);
                }
            })
    }, []);

    const apiGetComments = async (id) => {
        let response = { error: true, message: 'Hubo un error preparando la consulta' };

        response = await api.get("/tarjeta/comentario?id="+id.id+"&page=1&limit=10", {})
    
        if (response.error) {
            NotificationManager.error(response.message, '', 5000);
        } else {
            
        }

        return response;
    }

    const apiSubmitComment = async (id, obj) => {
        debugger;
        let response = { error: true, message: 'Hubo un error preparando la consulta' };

        response = await api.post("/tarjeta/comentario?id="+id.id, obj)
    
        if (response.error) {
            NotificationManager.error(response.message, '', 5000);
        } else {
            
        }

        return response;
    }

    const handleChangeNewComment = (e) => {
        setNewComment(e.target.value);
    }


    const SubmitComment = (e) => {
        e.preventDefault();
        apiSubmitComment(id, { comentario: newComment })
            .then(response => {
                if (response.error) {
                    NotificationManager.error(response.message, '', 5000);
                } else {
                    NotificationManager.success("Se ha agregado el comentario", '', 5000);
                    setComments({ count: comments.count + 1, rows: [response, ...comments.rows] });
                }
            })
            .catch()
            .finally(() => {
                setNewComment('');
            })
    }


    return <div className="card separate">
        <div className="card-body">
            <div>
                <h5><FontAwesomeIcon icon={faComments} className="fa-icon" /> Comentarios</h5>
                <hr />
            </div>
            <div >
                {
                    editable &&
                    <form onSubmit={SubmitComment}>
                        <label >Nuevo comentario</label>
                        <div className="input-group mb-3">
                            <input className="form-control" value={newComment} onChange={handleChangeNewComment} />
                            <div className="input-group-append">
                                <button className="btn btn-success"><FontAwesomeIcon icon={faPlus} className="fa-icon" /> Agregar</button>
                            </div>
                        </div>
                    </form>
                }

                <div className="table-responsive separate">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>descripción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                comments.count > 0 ?
                                    comments.rows.map(comment =>
                                        <tr key={comment.id} className={!comment.sistema ? 'bg-card-error' : ''} >
                                            <td>{moment(comment.createdAt).format("DD/MMM/YYYY hh:mm:ss a")}</td>
                                            <td> {comment.name}</td>
                                            <td>{comment.comentario}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={3}>No hay comentarios</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

}

export default ComentariosTarjeta;