import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { faLongArrowAltLeft, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "utils/hooks/useApi";
import { useLoader } from "utils/context/loaderContext";
import InfoColumn from "components/ui/infoColumn/infoColumn";
import { fromISOtoDatetime } from "utils/datetime/datetime";

const DetailsWorker = () => {
  const api = useApi();
  const [loader] = useLoader();

  const { id: idWorker } = useParams();
  const history = useHistory();

  const [worker, setWorker] = useState({});

  const getData = useCallback(() => {
    if (!idWorker) return;
    loader.set();
    api
      .get("cat/trab", { params: { id: idWorker } })
      .then(setWorker)
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idWorker, loader]);

  useEffect(getData, [getData]);

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="left">
              <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </button>
              <h5>Información de trabajador</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <InfoColumn
                colSize="col-12 col-md-4"
                title="Actividad"
                info={
                  <Fragment>
                    <span className="me-2">#{worker?.id}</span>
                    <span className={`badge ${worker?.activo ? "bg-success" : "bg-danger"}`}>
                      <FontAwesomeIcon icon={worker?.activo ? faToggleOn : faToggleOff} />
                    </span>
                  </Fragment>
                }
              />
              <InfoColumn colSize="col-12 col-md-4" title="Nombre" info={worker?.nombre} />
              <InfoColumn
                colSize="col-12 col-md-4"
                title="Fecha de registro"
                info={
                  <div className="lh-sm">
                    <span title="Última actualización">{fromISOtoDatetime(worker?.updatedAt)}</span>
                    <br />
                    <span className="fw-light" title="Creación">
                      {fromISOtoDatetime(worker?.createdAt)}
                    </span>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsWorker;
