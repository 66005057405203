import React from 'react';
import iconNoCards from '../../../imgs/icon-notarjetas.svg';

const TarjetasTrabajoNoCards = () => {
  return (
    <div className="tac separate">
      <div className="separate">
        <img className="" src={iconNoCards} alt="no-se-han-encontrado-tarjetas" />
      </div>
      <p className="separate">
        <b>No se han encontrado tarjetas</b>
      </p>
    </div>
  );
};

export default TarjetasTrabajoNoCards;