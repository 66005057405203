import jwt from "jsonwebtoken";

const storageType = process.env.REACT_APP_STORAGE || "local";
const apiURL = process.env.REACT_APP_API_URL || "base url missing from .env";

export function setToken(token) {
  if (storageType === "local") {
    localStorage.setItem("token", token);
  } else if (storageType === "session") {
    sessionStorage.setItem("token", token);
  }
}

export function getToken(decode = false) {
  let token = null;
  if (storageType === "local") {
    token = localStorage.getItem("token");
  } else if (storageType === "session") {
    token = sessionStorage.getItem("token");
  }

  if (decode) token = jwt.decode(token);
  return token;
}

export function setKey(key) {
  if (storageType === "local") {
    localStorage.setItem("publicKey", key);
  } else if (storageType === "session") {
    sessionStorage.setItem("publicKey", key);
  }
}

export function getKey() {
  let key = null;
  if (storageType === "local") {
    key = localStorage.getItem("publicKey");
  } else if (storageType === "session") {
    key = sessionStorage.getItem("publicKey");
  }

  return key;
}

export function logout() {
  if (storageType === "local") {
    localStorage.removeItem("token");
  } else if (storageType === "session") {
    sessionStorage.removeItem("token");
  }
}

export async function refreshToken() {
  try {
    const token = getToken();

    const response = await fetch(`${apiURL}/auth/refresh`, {
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      method: "POST",
    });

    if (!response.ok) {
      logout();
      throw Error(response.json());
    }

    const newToken = await response.json();
    setToken(newToken.token);
    return newToken.token;
  } catch (error) {
    console.error("BOILERPLATE ERROR: ", error);
    return null;
  }
}

export async function getPublicKey() {
  try {
    let key = getKey();
    if (!key) {
      const response = await fetch(`${apiURL}/auth/publickey`);
      if (!response.ok) throw Error(response.json());
      key = await response.text();
      setKey(key);
    }
    return key;
  } catch (error) {
    console.error("BOILERPLATE ERROR: ", error);
    return null;
  }
}

export async function getVerifiedToken(verify = true) {
  try {
    const token = getToken();
    let decoded = undefined;
    if (verify) {
      const key = await getPublicKey();
      decoded = jwt.verify(token, key);
    } else {
      decoded = jwt.decode(token);
    }
    return { token, decoded };
  } catch (error) {
    console.error("BOILERPLATE ERROR: ", error);
    return null;
  }
}

export function isTokenExpired(token) {
  try {
    const decoded = getToken(true);
    if (decoded.exp < Date.now() / 1000) return true;
    return false;
  } catch (error) {
    console.error("BOILERPLATE ERROR: ", error);
    return false;
  }
}
