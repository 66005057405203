import logo from "assets/logo-default.png";
import RouterAuth from "router/routerAuth";

const Auth = () => {
  return (
    <div className="auth">
      <div className="container py-3">
        <div className="row justify-content-center align-items-center">
          <div className="col col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="header">
                  <img src={logo} alt="Logo" className="logo" />
                  {/* <h1>Auth Title</h1> */}
                </div>
                <div className="body py-3">
                  <RouterAuth />
                </div>
                <div className="footer">
                  <p className="m-0">Necodex 2021.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
