import MobileSidebar from "components/ui/mobileSidebar/mobileSidebar";
import Sidebar from "components/ui/sidebar/sidebar";
import { useState } from "react";
import RouterPanel from "router/routerPanel";

const Panel = () => {
  const [container] = useState(false);

  return (
    <div className="panel">
      <div className="header">
        <MobileSidebar />
      </div>
      <div className="body">
        <div className="left">
          <Sidebar />
        </div>
        <div className="right">
          <div className={container ? "container-fluid" : "container"}>
            <div className="row">
              <div className="col">
                <RouterPanel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
