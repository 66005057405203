import { Fragment } from "react";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHeader from "components/ui/sectionHeader/sectionHeader";
import NavButttons from "components/ui/navButtons/navButtons";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import TableActivities from "./activities/tableActivities";
import DetailsActivity from "./activities/detailsActivity";
import RegisterActivity from "./activities/registerActivity";
import TableWorkers from "./workers/tableWorkers";
import RegisterWorker from "./workers/registerWorker";
import DetailsWorker from "./workers/detailsWorker";
import RegisterCrop from "./crops/registerCrop";
import DetailsCrop from "./crops/detailsCrop";
import TableCrops from "./crops/tableCrops";
import TableBatches from "./batches/tableBatches";
import RegisterBatch from "./batches/registerBatch";
import DetailsBatch from "./batches/detailsBatch";
import DetailsTree from "./batches/detailsTree";

const Catalogs = () => {
  const { url } = useRouteMatch();

  return (
    <div>
      <SectionHeader
        leftContent={
          <Fragment>
            <FontAwesomeIcon icon={faFolderOpen} className="fa-icon" />
            <h2>Catálogos</h2>
          </Fragment>
        }
      />
      <NavButttons
        buttons={[
          { path: `${url}/actividades`, title: "Actividades" },
          { path: `${url}/cultivos`, title: "Cultivos" },
          { path: `${url}/lotes`, title: "Lotes" },
          { path: `${url}/trabajadores`, title: "Trabajadores" },
        ]}
      />
      <Switch>
        <Redirect exact from={`${url}`} to={`${url}/actividades`} />
        <Redirect exact from={`${url}/actividades`} to={`${url}/actividades/tabla`} />
        <Route exact path={`${url}/actividades/registro`} component={RegisterActivity} />
        <Route exact path={`${url}/actividades/edicion/:id`} component={RegisterActivity} />
        <Route exact path={`${url}/actividades/detalles/:id`} component={DetailsActivity} />
        <Route exact path={`${url}/actividades/tabla`} component={TableActivities} />
        <Redirect exact from={`${url}/cultivos`} to={`${url}/cultivos/tabla`} />
        <Route exact path={`${url}/cultivos/registro`} component={RegisterCrop} />
        <Route exact path={`${url}/cultivos/edicion/:id`} component={RegisterCrop} />
        <Route exact path={`${url}/cultivos/detalles/:id`} component={DetailsCrop} />
        <Route exact path={`${url}/cultivos/tabla`} component={TableCrops} />
        <Redirect exact from={`${url}/lotes`} to={`${url}/lotes/tabla`} />
        <Route exact path={`${url}/lotes/registro`} component={RegisterBatch} />
        <Route exact path={`${url}/lotes/edicion/:id`} component={RegisterBatch} />
        <Route exact path={`${url}/lotes/detalles/:id`} component={DetailsBatch} />
        <Route path={`${url}/lotes/detalles/arbol/detalleArbol/:id`} component={DetailsTree} />
        <Route exact path={`${url}/lotes/tabla`} component={TableBatches} />
        <Redirect exact from={`${url}/trabajadores`} to={`${url}/trabajadores/tabla`} />
        <Route exact path={`${url}/trabajadores/registro`} component={RegisterWorker} />
        <Route exact path={`${url}/trabajadores/edicion/:id`} component={RegisterWorker} />
        <Route exact path={`${url}/trabajadores/detalles/:id`} component={DetailsWorker} />
        <Route exact path={`${url}/trabajadores/tabla`} component={TableWorkers} />
      </Switch>
    </div>
  );
};

export default Catalogs;
