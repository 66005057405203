import React from 'react';
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHeader from "components/ui/sectionHeader/sectionHeader";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import TarjetasTrabajoFilters from './tarjetasTrabajoFilters';
import './tarjetasTrabajo.css';
import TarjetasTrabajoCard from './tarjetasTrabajoCard';
import TarjetasTrabajoNoCards from './tarjetasTrabajoNoCard';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { useLocation, useHistory } from 'react-router-dom';

const today = moment().format('YYYY-MM-DD');
const weekStart = moment().startOf("week").format('YYYY-MM-DD');

const reducer = (state, action) => {
    switch (action.type) {
      case 'FILTERS_SET':
        return {
          ...state,
          filters: {
            ...action.payload,
          },
          cards: initialState.cards,
        };
      case 'FILTERS_CLEAR':
        return {
          ...state,
          filters: {
            ...initialState.filters,
          },
          cards: initialState.cards,
        };
      case 'FILTERS_RFID_SET':
        return {
          ...state,
          filters: {
            ...state.filters,
            rfid: action.payload,
            page: 1,
          },
        };
      case 'CARDS_PUSH':
        return {
          ...state,
          cards: {
            ...state.cards,
            count: action.payload.count,
            rows: [...state.cards.rows, ...action.payload.rows],
          },
        };
      case 'CARDS_SET':
        return {
          ...state,
          cards: {
            ...state.cards,
            count: action.payload.count,
            rows: action.payload.rows,
            page: 1,
          },
        };
      case 'CARDS_SET_PAGE':
        return {
          ...state,
          cards: {
            ...state.cards,
            page: action.payload,
          },
        };
      case 'MULTISELECT_SET_SELECTING':
        return {
          ...state,
          multiSelect: {
            ...state.multiSelect,
            selecting: action.payload,
          },
        };
      case 'MULTISELECT_CANCEL':
        return {
          ...state,
          multiSelect: initialState.multiSelect,
          cards: {
            ...state.cards,
            rows: state.cards.rows.map(card => ({ ...card, selected: false })),
          },
        };
      case 'MULTISELECT_TOGGLE_ID':
        return {
          ...state,
          cards: {
            ...state.cards,
            rows: state.cards.rows.map(card =>
              card.id === action.payload ? { ...card, selected: !card.selected } : card,
            ),
          },
        };
      default:
        return state;
    }
  };

    const initialState = {
        filters: {
            type: '',
            search: '',
            id_horario: 0,
            fecha_inicial: weekStart,
            fecha_final: today
        },
        cards: {
            count: null,
            rows: [],
            page: 1,
            limit: 9,
        }
    };

const TarjetasTrabajo = ({ location: propsLocation }) => {
    const api = useApi();
    const [tipoTarjetas, setTipoTarjetas] = useState([]);
    //const [cards, setCards] = useState([]);
    const [loader] = useLoader();
    const [tabTarjeta, setState] = useState('Sincronizada');
    const [idTabSelected, setIdTabSelected] = useState(1);

    const [state, dispatch] = React.useReducer(reducer, initialState, initialState => {
        let preparedState = {
          ...initialState
        };
        if (propsLocation.state) {
          preparedState = {
            ...preparedState,
            ...(propsLocation.state.tabs && { tabs: propsLocation.state.tabs }),
            ...(propsLocation.state.filters && { filters: propsLocation.state.filters }),
            ...(propsLocation.state.keepPage && {
              cards: {
                ...initialState.cards,
                page: propsLocation.state.keepPage,
              },
            }),
          };
        }
        return preparedState;
      });

      const { filters, cards } = state;

        const location = useLocation();
        const history = useHistory();

      const getCards = React.useCallback(
        async (clearArray = false) => {
          try {
            const estimatedCards = cards.limit * cards.page;
            if (!clearArray && cards.count !== null && (cards.rows.length >= cards.count || estimatedCards === cards.rows.length)) {
              // IMPORTANT avoids infinite loop.
              // cards.count is null on first render. This allows to do the first call.
              // Check if cards length >= cards count, this means we already have all the cards, no need to query for more.
              // Check if estimatedcards equals the actual number of current cards (endpoint already called, no need to call again).
              return;
            }
    
            if (filters.search && !filters.type) {
              NotificationManager.warning('Seleccione el tipo de búsqueda', '', 5000);
              return;
            }
    
    
            const queryParams = {
              fecha_inicial: filters.fecha_inicial,
              fecha_final: filters.fecha_final,
              id_estatus: idTabSelected,
              ...(filters.id_horario !== "" && { id_horario: filters.id_horario }),
              ...(filters.search &&
                filters.type && {
                  search: filters.search,
                  type_search: filters.type,
                }),
              limit: cards.limit,
              page: cards.page,
            };
    
            // This allows to get all cards previously loaded on first load (when comming back from details).
            if (cards.count === null && location.state && location.state.keepPage) {
              queryParams.page = 1;
              queryParams.limit = location.state.keepPage * cards.limit;
            }
    
            // TODO: Probar cuando Alexis repare el error 500.
            if (clearArray) {
              queryParams.page = 1;
              queryParams.limit = cards.page * cards.limit;
            }
    
            let response = { error: true, message: 'Hubo un error preparando la consulta' };
    

            let strfiltros = makeUrlGET(queryParams);
            response = await api.get("/tarjeta/list?" + strfiltros, {})
    
            if (response.error) {
              NotificationManager.error(response.message, '', 5000);
            } else {
              dispatch({
                type: cards.page === 1 || clearArray ? 'CARDS_SET' : 'CARDS_PUSH',
                payload: {
                  count: response.count,
                  rows: response.rows,
                },
              });
            }
    
          } catch (error) {
            console.error(error);
          }
        },
        [filters, cards, location.state, api, idTabSelected],
      );
      

    // GET cards
    React.useEffect(() => {
        getCards();
    }, [getCards]);

    const getData = useCallback(() => {
        loader.set();
        api
          .get("tarjeta/count", {})
          .then(setTipoTarjetas)
          .catch(console.error)
          .finally(loader.stop);
      }, [api, loader]);

    const setSelected = (tabName) => {
        limpiarFiltros();
        setIdTabSelected(tabName.id);
        setState(tabName.descripcion);
    }

    const makeUrlGET = objs => {
        let params = [];
        for (const key in objs) {
            const val = objs[key];
            if (val) {
                params.push(`${key}=${val}`);
            }
        }
        return params.join('&');
    };

    const handleOnScroll = ({ currentTarget, target }) => {
        if (currentTarget.scrollHeight - target.scrollTop === target.clientHeight) {
          const pages = Math.ceil(cards.count / 9);
          if (pages > cards.page) {
            dispatch({ type: 'CARDS_SET_PAGE', payload: cards.page + 1 });
          }
        }
      };

      const goToDetails = id => {
        history.replace({
          pathname: location.pathname,
          state: {
            filters,
            // To know which page to load when back from details
            keepPage: cards.page,
          },
        });
        history.push(`detalle/${id}`);
      };

    const limpiarFiltros = () => {
        dispatch({ type: 'FILTERS_CLEAR', payload: null });
        history.replace({
            pathname: location.pathname,
        });
    }

    const handleCardClick = id => {
        goToDetails(id);
    };

    useEffect(getData, [getData]);
    

    return (
        <div className="container-page-wrapper sizeDiv">
            <SectionHeader
                leftContent={
                <Fragment>
                    <FontAwesomeIcon icon={faClipboardList} className="fa-icon" />
                    <h2>Tarjetas de trabajo</h2>
                </Fragment>
                }
            />
            <div className="tabs-wrapper">
                <div className="btn-group btn-tabs" role="group">
                {tipoTarjetas.map(tab => (
                    <button
                        key={tab.descripcion}
                        type="button"
                        onClick={() => setSelected(tab)}
                        className={'btn ' + (tabTarjeta === tab.descripcion ? 'btn-primary' : 'btn-light')}
                    >
                        <label>{tab.descripcion}</label>
                        <span className="badge badge-pill badge-info" style={{ backgroundColor: 'grey', color: 'white', marginLeft: '.5rem', borderRadius: '20%' }}>
                            {tab.num_tarjetas}
                        </span>
                    </button>
                    ))}
                </div>
            </div>
            <TarjetasTrabajoFilters
                onFilter={values => dispatch({ type: 'FILTERS_SET', payload: { ...values, filtering: true } })}
                onClear={limpiarFiltros}
                initialFilters={filters}
            />
            <div className="scrollable-div" onScroll={handleOnScroll}>
                {cards.count > 0 && (
                <div className="row">
                    {cards.rows.map(card => (
                    <TarjetasTrabajoCard
                        key={card.id}
                        card={card}
                        onButtonClick={handleCardClick}
                    />
                    ))}
                </div>
                )}
                {(cards.count === 0 || cards.count === null || cards.count === undefined)  && <TarjetasTrabajoNoCards />}
            </div>
        </div>
        


    )
}

export default TarjetasTrabajo;