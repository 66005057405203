import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const InfoTarjeta = props => {

    const { card: cardinfo } = props;
    
    const [card, setCard] = React.useState(cardinfo);

    React.useEffect(() => {
        setCard(cardinfo);
        console.log(card);
    }, [cardinfo, card]);


    return (
        <div className="card separate">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <h5>
                            <FontAwesomeIcon icon={faIdCard} className="fa-icon" /> Información tarjeta
                        </h5>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Folio</label>
                            <p>{card.card.folio}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Estado</label>
                            <p>{card.card.estatus}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Fecha</label>
                            <p>{moment(card.card.createdAt).format('DD/MMM/YYYY')}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Día</label>
                            <p>{card.card.dia}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">No de semana</label>
                            <p>{card.card.semana}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">No. de trabajadores</label>
                            <p>{card.card.num_trabajadores}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Turno</label>
                            <p>{card.card.horario.descripcion}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Hora inicio</label>
                            <p>{card.card.horario.hora_inicio}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Hora fin </label>
                            <p>{card.card.horario.hora_fin}</p>
                        </div>
                        <div className="col-md-2 col-12">
                            <label className="lbl-title">Tipo actividad </label>
                            <p>{card.card.tipoActividad.nombre}</p>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Apuntador</label>
                            {card.card.apuntador !== null && (<p>{card.card.apuntador.id + ' - ' + card.card.apuntador.nombre}</p>)}
                            {card.card.apuntador === null && (<p>No aplica</p>)}
                        </div>
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Supervisor</label>
                            {card.card.supervisor !== null && (<p>{card.card.supervisor.id + ' - ' + card.card.supervisor.nombre}</p>)}
                            {card.card.supervisor === null && (<p>No aplica</p>)}
                        </div>
                        <div className="col-md-4 col-12">
                            <label className="lbl-title">Cuadrillero</label>
                            {card.card.cuadrillero !== null && (<p>{card.card.cuadrillero.id + ' - ' + card.card.cuadrillero.nombre}</p>)}
                            {card.card.cuadrillero === null && (<p>No aplica</p>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

InfoTarjeta.defaultProps = {
    cardinfo: []
}

export default InfoTarjeta;