import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "utils/hooks/useApi";
import { useLoader } from "utils/context/loaderContext";
import { faLongArrowAltLeft, faSave, faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import InfoColumn from "components/ui/infoColumn/infoColumn";
import Swal from "sweetalert2";  
import LogArbolIncidencia from "./logArbolIncidencia";
import LogActividadesArbol from "./logActividadesArbol";
import LogActividadesLinea from "./logActividadesLinea";
import LogActividadesLote from "./logActividadesLote";

const DetailsTree = () => {
    const api = useApi();
    const [loader] = useLoader();
    const { id: idArbol } = useParams();
    const history = useHistory();
    const [estatusArbol, setEstatusArbol] = useState([]);

    const [row, setRow] = useState({});
    const [arbol, setArbol] = useState({});

    const getData = useCallback(() => {
        if (!idArbol) return;
        loader.set();
        api
          .get("/cat/lote/arbol/info?id="+idArbol, {})
          .then(setArbol)
          .catch(console.error)
          .finally(loader.stop, getEstatusArbol());
      }, [api, idArbol, loader]);

    const updateEstatusArbol = async (objEstatus) => {
        debugger;
        console.log(objEstatus);
        loader.set();

        try {
            await api
            .put("/cat/lote/arbol?id="+objEstatus.id, { id_estatus: objEstatus.id_estatus})
            .then(res => {
                console.log("Se hizo correctamente el update del detalle de arbol");
                Swal.fire({  
                    title: 'Completado!',  
                    type: 'success',  
                    text: 'Se ha cambiado el estatus del árbol correctamente.',  
                });
            });
            
        } catch (error) {
            console.error(error);
        }
    }

    const getEstatusArbol = async () => {
        loader.set();
        try {
            await api
            .get("/cat/lote/estatus", {})
            .then(res => {
            res = res.map(estatusArbol => ({ id: estatusArbol.id, descripcion: estatusArbol.descripcion }));
            console.log('Valor de los estatus de los arboles ', res);
            setEstatusArbol(res);
            })
        } catch (error) {
            console.error(error);
        }
        loader.stop();
    }

    const cambiarEstatusArbol = (e, estatusArbol) => {
        debugger;
        estatusArbol.id_estatus = e.target.value;
        setRow(estatusArbol);
      }
    
    useEffect(getData, [getData]);

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                <div className="card-header">
                    <div className="left">
                    <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faLongArrowAltLeft} />
                    </button>
                    <h5>Detalles de árbol</h5>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <InfoColumn colSize="col-12 col-md-4" title="ID árbol" info={
                            <Fragment>
                                <span className="me-2">#{arbol?.id}</span>
                                <span className={`badge ${arbol?.activo ? "bg-success" : "bg-danger"}`}>
                                <FontAwesomeIcon icon={arbol?.activo ? faToggleOn : faToggleOff} />
                                </span>
                            </Fragment>
                        }/>
                        <InfoColumn colSize="col-12 col-md-4" title="Nombre del árbol" info={arbol?.nombre} />
                        <InfoColumn colSize="col-12 col-md-4" title="Línea" info={arbol?.linea} />
                        <label>Estatus árbol</label>
                        <div className="form-row">
                            <div className="col-4">
                                <div className="input-group col-md-4">
                                    <select className="form-control" id="selectLinea" onChange={(e) => cambiarEstatusArbol(e, arbol)}>
                                        {Object.entries(estatusArbol).map(([id, v]) => (  
                                        <option selected={v.id === arbol.id_estatus} key={id} value={v.id}>{v.descripcion}</option>
                                        ))}
                                    </select>
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" onClick={() => updateEstatusArbol(row)}>
                                            <FontAwesomeIcon icon={faSave} />
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <hr/>
                    <LogArbolIncidencia/>
                    <hr/>
                    <LogActividadesArbol/>
                    <hr/>
                    <LogActividadesLinea/>
                    <hr/>
                    <LogActividadesLote/>
                </div>
                </div>
            </div>
    
        </div>
    )
}


export default DetailsTree;