import { useCallback, useMemo, useRef, useState } from "react";
import { faBars, faPencilAlt, faPlus, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "@wafo/table";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import useQuery from "utils/hooks/useQuery";

const TableWorkers = () => {
  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  const [loader] = useLoader();
  const api = useApi();

  const tableRef = useRef();
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const getData = useCallback(
    async ({ size, page, search }) => {
      loader.set();
      api
        .get("/cat/trab/list", {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        })
        .then(resp => {
          setRows(
            resp.rows.map(row => ({
              id: row.id,
              nombre: row.nombre,
              options: row,
            })),
          );
          setTotalRows(resp.count);

          history.replace({
            pathname: match.url,
            search: `?page=${page}`,
          });
        })
        .catch(console.error)
        .finally(loader.stop);
    },
    [history, match.url, api, loader],
  );

  const toggleActive = useCallback(
    idWorker => {
      loader.set();
      api
        .put("/cat/trab/status", null, {
          params: {
            id: idWorker,
          },
        })
        .catch(console.error)
        .finally(() => {
          tableRef.current.forceUpdate();
          loader.stop();
        });
    },
    [api, loader],
  );

  const columnsConfig = useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      options: {
        render: val => (
          <div className="btn-row">
            <button
              type="button"
              className={`btn btn-sm ${val.activo ? "btn-success" : "btn-danger"}`}
              title="Habilitar / Inhabilitar"
              onClick={() => toggleActive(val.id)}
            >
              <FontAwesomeIcon icon={val.activo ? faToggleOn : faToggleOff} />
            </button>
            <Link
              to={{
                pathname: `/panel/catalogos/trabajadores/edicion/${val.id}`,
                state: { worker: val },
              }}
              className="btn btn-sm btn-primary"
              title="Editar"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>
            <Link
              to={{
                pathname: `/panel/catalogos/trabajadores/detalles/${val.id}`,
                state: { worker: val },
              }}
              className="btn btn-sm btn-secondary"
              title="Detalles"
            >
              <FontAwesomeIcon icon={faBars} />
            </Link>
          </div>
        ),
        style: { width: "15%" },
      },
    }),
    [toggleActive],
  );

  return (
    <DataTable
      ref={tableRef}
      locale="es"
      columns={["ID", "Nombre", "Opciones"]}
      rows={rows}
      totalRows={totalRows}
      tableClass="table table-sm table-striped table-borderless"
      wrapperClass="card-table"
      tableWrapperClass="wafotable-wrapper"
      onPagination={getData}
      noRowsMessage="Sin registros por mostrar"
      initialPage={parseInt(query.get("page"), 10)}
      columnsConfig={columnsConfig}
      controls={
        <div className="w-100 d-flex justify-content-end">
          <Link to="/panel/catalogos/trabajadores/registro" className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} className="fa-icon" />
            <span>Nuevo trabajador</span>
          </Link>
        </div>
      }
    />
  );
};

export default TableWorkers;
