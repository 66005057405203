import { useReducer, createContext, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

const AlertContext = createContext();

const initialState = [];

const defaultAlert = {
  uuid: uuidv4(),
  type: "dark",
  title: "Alert",
  message: "Alert message",
  timer: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case "ALERT_ADD":
      return [
        ...state,
        {
          ...defaultAlert,
          ...action.payload,
        },
      ];
    case "ALERT_REMOVE": {
      const index = state.findIndex(x => x.uuid === action.payload);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case "ALERT_ERROR":
      return [
        ...state,
        {
          ...defaultAlert,
          type: "danger",
          title: "Error",
          timer: 30000,
        },
      ];
    default:
      throw new Error("No type on action for AlertContext");
  }
}

function AlertProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AlertContext.Provider value={{ state, dispatch }} {...props} />;
}

const useAlerts = () => {
  const { state, dispatch } = useContext(AlertContext);

  const actions = useMemo(
    () => ({
      set: alert => dispatch({ type: "ALERT_ADD", payload: alert }),
      remove: uuid => dispatch({ type: "ALERT_REMOVE", payload: uuid }),
      error: alert => dispatch({ type: "ALERT_ERROR", payload: alert }),
    }),
    [dispatch],
  );

  return [actions, state];
};

export { AlertProvider, useAlerts };
