import { faFileExcel, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormBatch from "components/forms/catalogs/formBatch";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import FileSaver from "file-saver";

const RegisterBatch = () => {
  const history = useHistory();
  const { id: idBatch } = useParams();

  const api = useApi();
  const [loader] = useLoader();

  const downloadTemplate = useCallback(() => {
    loader.set();
    api
      .get("/cat/lote/template", { responseType: "blob" })
      .then(resp => {
        FileSaver.saveAs(resp.data, "lote_template.xlsx");
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, loader]);

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="left">
              <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </button>
              <h5>Información de lote</h5>
            </div>
            <div className="right">
              {!idBatch && <button type="button" className="btn btn-sm btn-success" onClick={downloadTemplate} >
                <FontAwesomeIcon icon={faFileExcel} className="fa-icon" />
                <span>Descargar plantilla</span>
              </button>}
            </div>
          </div>
          <div className="card-body">
            <FormBatch onAfterSubmit={() => history.goBack()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterBatch;
