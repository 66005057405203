import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const ListaTrabajoTarjeta = props => {
    const { listaTrabajo, tipoActividad } = props;

    const listaTrabajoList = React.useMemo(() => {
      debugger;
        console.log("Lista Trabajo: "+listaTrabajo);
        let listaTrabajoTemp = listaTrabajo.map((a, index) => ({ ...a, obj:a, i: index }));
        console.log("ListaTrabajoTemp: "+listaTrabajoTemp)
        return listaTrabajoTemp;
    }, [listaTrabajo]);


    return (
        <div className="card separate">
          <div className="card-body">
            <div className="row no-row-margin">
              <div className="col-md-6 col-12">
                <h5>
                  <FontAwesomeIcon icon={faIdCard} className="fa-icon" /> {(tipoActividad.nombre === "Por línea" ? "Líneas trabajadas" : (tipoActividad.nombre === "Por árbol" ? "Árboles trabajados" : ""))}
                </h5>
              </div>
            </div>
            <div>
              <hr/>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Descripción</th>                      
                    </tr>
                  </thead>
                  <tbody>
                  {listaTrabajoList.map(listTrabajo => {
                  return (
                    <React.Fragment key={listTrabajo.i}>
                      <tr className="">
                        <td>{listTrabajo.obj}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    };

ListaTrabajoTarjeta.defaultProps = {
    listaTrabajo: [],
  };
  
export default ListaTrabajoTarjeta;