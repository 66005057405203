import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput, WafoFormSelect } from "@wafo/forms";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";

const FormAdmin = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();

  const { state: locationState } = useLocation();

  
  const [trabajador, setTrabajadores] = useState([]);

  const [admin] = useState(() => {
    if (locationState && locationState.admin) {
      return locationState.admin;
    }
    return {};
  });

  // Load types
  useEffect(() => {
    loader.set();
    api
      .get("/cat/trab/list")
      .then(resp => {
        setTrabajadores(
          resp.rows.map(x => ({
            value: x.id,
            display: x.nombre,
          })),
        );
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, loader]);

  const onSubmit = async (form, values) => {
    if (!form.valid) return;
    loader.set();
    try {
      if (admin?.id) {
        await api.put("/user/admin", { ...values }, { params: { id: admin.id } });
      } else {
        await api.post("/user/admin", { ...values });
      }
      onAfterSubmit();
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  return (
    <WafoForm formId="form-admin" locale="es" onSubmit={onSubmit} values={admin} ignoreEmpty>
      <WafoFormInput
        type="text"
        name="username"
        label="Nombre de usuario"
        placeholder="Ingrese nombre de usuario"
        customClass="col-12 col-md-3"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormInput
        type="text"
        name="name"
        label="Nombre (completo)"
        placeholder="Ingrese nombre de administrador"
        customClass="col-12 col-md-3"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormSelect
        name="id_trabajador"
        label="Trabajador"
        defaultValue="Seleccione el trabajador"
        options={trabajador}
        customClass="col-12 col-md-3"
        validations={{ required: false }}
      />

      <WafoFormInput
        type="password"
        name="password"
        label="Contraseña"
        placeholder="Ingrese contraseña"
        customClass="col-12 col-md-3"
        validations={{ required: !admin?.id, maxLength: 100, minLength: 6 }}
      >
        {admin?.id && <p className="extra">Dejar vacio para conservar contraseña actual</p>}
      </WafoFormInput>

      <div className="col-12 mt-3 text-center">
        <button type="submit" form="form-admin" className="btn btn-primary">
          <FontAwesomeIcon icon={faSave} className="fa-icon" />
          <span>Guardar cambios</span>
        </button>
      </div>
    </WafoForm>
  );
};

export default FormAdmin;
