import { useReducer, createContext, useContext, useMemo } from "react";

const LoaderContext = createContext();

const initialState = [];

function reducer(state, action) {
  switch (action.type) {
    case "LOADER_ADD":
      return [...state, true];
    case "LOADER_REMOVE":
      if (state.length > 0) {
        const loading = [...state];
        loading.pop();
        return loading;
      }
      return state;
    default:
      throw new Error("No type on action for LoaderContext");
  }
}

function LoaderProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LoaderContext.Provider value={{ state, dispatch }} {...props} />;
}

const useLoader = () => {
  const { state, dispatch } = useContext(LoaderContext);

  const actions = useMemo(
    () => ({
      set: () => dispatch({ type: "LOADER_ADD" }),
      stop: () => dispatch({ type: "LOADER_REMOVE" }),
    }),
    [dispatch],
  );

  return [actions, state];
};

export { LoaderProvider, useLoader };
