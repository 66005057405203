import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput, WafoFormMultiSelect, WafoFormSelect } from "@wafo/forms";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";

const FormActivity = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();

  const { id: idActivity } = useParams();
  const { state: locationState } = useLocation();

  const [activity, setActivity] = useState(() => {
    if (locationState && locationState.activity) {
      return {
        ...locationState.activity,
        id_tipo_actividad: "",
        supervisor: false,
        incidencia: false,
      };
    }
    return {};
  });
  const [tiposActividad, setTiposActividad] = useState([]);

  const [isCheckedSC, setIsCheckedSC] = useState(false);
  const [isCheckedIncidencia, setIsCheckedIncidencia] = useState(false);

  // Load types
  useEffect(() => {
    loader.set();
    api
      .get("/cat/act/tipo")
      .then(resp => {
        setTiposActividad(
          resp.map(x => ({
            value: x.id,
            display: x.nombre,
          })),
        );
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, loader]);

  // Load activity
  useEffect(() => {
    if (!idActivity) return;
    loader.set();
    api
      .get("cat/act", { params: { id: idActivity } })
      .then(res => {
        setActivity(res);
        setIsCheckedSC(res.supervisor);
        setIsCheckedIncidencia(res.incidencia);
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idActivity, loader]);

  const onSubmit = async (form, values) => {
    debugger;
    if (!form.valid) return;
    loader.set();
    values = {...values, supervisor: isCheckedSC, incidencia: isCheckedIncidencia};
    try {
      if (activity?.id) {
        await api.put("/cat/act", { ...values }, { params: { id: activity.id } });
      } else {
        await api.post("/cat/act", { ...values });
      }
      onAfterSubmit();
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };


  
  return (
    <WafoForm formId="form-activity" locale="es" onSubmit={onSubmit} values={activity} ignoreEmpty>
      <WafoFormInput
        type="text"
        name="nombre"
        label="Nombre de actividad"
        placeholder="Ingrese nombre"
        customClass="col-12 col-md-5"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormSelect
        name="id_tipo_actividad"
        label="Tipo de actividad"
        defaultValue="Seleccione el tipo"
        options={tiposActividad}
        customClass="col-12 col-md-5"
        validations={{ required: true }}
      />
      
      <div className="form-row">
        <div className="form-check col-md-4">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(event) => setIsCheckedSC(event.currentTarget.checked)}
            checked={isCheckedSC}
            validations={{
              required: true
            }}
          />
          <label className="form-check-label" for="flexCheckDefault">
            Requiere supervisor/cuadrillero
          </label>
        </div>
        <div className="form-check col-md-3">
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(event) => setIsCheckedIncidencia(event.currentTarget.checked)}
            checked={isCheckedIncidencia}
            validations={{
              required: true
            }}
          />
          <label className="form-check-label" for="flexCheckDefault">
            Resuelve incidencia
          </label>
        </div>
      </div>

      <div className="col-12 mt-3 text-center">
        <button type="submit" form="form-activity" className="btn btn-primary">
          <FontAwesomeIcon icon={faSave} className="fa-icon" />
          <span>Guardar cambios</span>
        </button>
      </div>
    </WafoForm>
  );
};

export default FormActivity;
