const FilePicker = ({ customClass, name, label, handleInputChange, valid, touched, errors }) => {
  const handleOnChange = ({ target: { files } }) => {
    if (files.length) {
      handleInputChange({
        target: {
          name,
          value: files[0],
        },
        type: "change",
      });
    }
  };

  return (
    <div className={`wafo-wrapper ${customClass}`}>
      <div className="wafoform form-group wafo-input">
        {label && <label htmlFor={name}>{label}</label>}
        <input
          type="file"
          id={name}
          name={name}
          accept=".xls, .xlsx"
          className="form-control"
          onChange={handleOnChange}
          ignoreinput="true"
        />
      </div>
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilePicker;
