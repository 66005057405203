import React from 'react';

const InfoColumn = ({ colSize, title, info, empty }) => {
  return (
    <div className={colSize}>
      <div className="info-column">
        <span>{title}</span>
        <span>{info}</span>
        {!info && <span className="empty">{empty}</span>}
      </div>
    </div>
  );
};

export default InfoColumn;