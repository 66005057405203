import { faPlus, faSave, faTimes, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput } from "@wafo/forms";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";  

const FormCrop = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();

  const { id: idCrop } = useParams();
  const { state: locationState } = useLocation();

  const [crop, setCrop] = useState(() => {
    if (locationState && locationState.crop) {
      return {
        ...locationState.crop,
        variedades: {},
      };
    }
    return { variedades: {} };
  });

  useEffect(() => {
    if (!idCrop) return;
    loader.set();
    api
      .get("/cat/cult", { params: { id: idCrop } })
      .then(resp => {
        setCrop({
          ...resp,
          variedades: resp.variedades.reduce((accumulator, variedad) => {
            const uuid = uuidv4();
            console.log(variedad);
            accumulator[uuid] = {
              ...variedad,
            };
            return accumulator;
          }, {}),
        });
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idCrop, loader]);

  const addVariety = () => setCrop(prev => ({ ...prev, variedades: { ...prev.variedades, [uuidv4()]: {} } }));
  /*const removeVariety = uuid => {
    setCrop(prev => {
      const variedades = { ...prev.variedades };
      delete variedades[uuid];
      return { ...prev, variedades };
    });
  };*/

  /*const validarEspacios = (str) => {
    debugger;
    if(str.nombre.trim() === ""){
      console.log("está vacío")
    }else{
      console.log("hay texto")
    }
  }*/

  const onSubmit = async (form, values) => {
    if (!form.valid) return;
    loader.set();
    try {
      if (crop?.id) {
        const variedadesPush = Object.entries(values.variedades).map(([uuid, v]) => ({
          id: v.id,
          nombre: v.nombre,
        }));

        for (let i = 0; i < variedadesPush.length; i++) {
          if(typeof variedadesPush[i].id === 'undefined'){
            await api.post("/cat/var?id="+crop?.id, {
              nombre: variedadesPush[i].nombre,
            }); 
          }

          if(variedadesPush[i].nombre.trim() === ""){
            Swal.fire({  
              title: 'Error',  
              type: 'success',  
              text: 'Debe ingresar todos los campos para continuar.',  
            });
            return form.valid = false;
          }
        }


        await api.put("/cat/cult", { nombre: values.nombre }, { params: { id: crop.id } });
        await Promise.allSettled(
          Object.entries(values.variedades).map(([uuid, v]) =>
            api.put("/cat/var", { nombre: v.nombre }, { params: { id: v.id } }),
          ),
        );
      } else {
        const variedades = Object.entries(values.variedades).map(([uuid, v]) => ({
          nombre: v.nombre,
        }));
        await api.post("/cat/cult", {
          nombre: values.nombre,
          variedades,
        });
      }
      onAfterSubmit();
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  return (
    <WafoForm formId="form-worker" locale="es" onSubmit={onSubmit} values={crop} ignoreEmpty>
      <WafoFormInput
        type="text"
        name="nombre"
        label="Nombre de cultivo"
        placeholder="Ingrese nombre"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <div className="col-12">
        <div className="form-divider">
          <h5>Variedades</h5>
          <button type="button" className="btn btn-sm btn-secondary" onClick={addVariety}>
            <FontAwesomeIcon icon={faPlus} className="fa-icon" />
            <span>Añadir variedad</span>
          </button>
        </div>
      </div>
      <div className="col" groupname="variedades">
        <table className="table table-borderless table-striped">
          
          <thead>
            <tr>
              <th scope="col">Nombre de variedad</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(crop.variedades || {}).map(([uuid, v]) => (
              <tr key={uuid} groupname={uuid}>
                <td>
                  <input type="number" name="id" style={{ display: "none" }} />
                  <WafoFormInput
                    type="text"
                    name="nombre"
                    label=""
                    placeholder="Ingrese nombre"
                    customClass="col-12 col-md-4"
                    validations={{
                      required: true,
                      maxLength: 254,
                    }}
                  />
                </td>
                
              </tr>
            ))}
            {!crop.variedades ||
              (Object.entries(crop.variedades)?.length === 0 && (
                <tr>
                  <td colSpan="2" className="text-center">
                    Sin variedades registradas
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="col-12 mt-3 text-center">
        <button type="submit" form="form-worker" className="btn btn-primary">
          <FontAwesomeIcon icon={faSave} className="fa-icon" />
          <span>Guardar cambios</span>
        </button>
      </div>
    </WafoForm>
  );
};

export default FormCrop;
