
import React from 'react';
import ActividadesTarjeta from './actividadesTarjeta';
import CultivosTarjeta from './cultivosTarjeta';
import InfoTarjeta from './infoTarjeta';
import LotesTarjeta from './lotesTarjeta';
import TrabajadoresTarjeta from './trabajadoresTarjetas';
import ComentariosTarjeta from './comentariosTarjeta';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import useApi from "utils/hooks/useApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faSync, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from '../../../Modals/confirm';
import ModalRechazar from '../modals/modalIncidencia';
import ModalAprobar from '../modals/modalAprobar';
import 'react-notifications/lib/notifications.css';
import ListaTrabajoTarjeta from './listaTrabajoTarjeta';

const reducer = (state, action) => {
    switch (action.type) {
      case 'setCard':
        return { ...state, card: action.payload };
      case 'setModalShow':
        return { ...state, showModal: action.payload };
      case 'setModalRechazarShow':
        return { ...state, modalRechazarShow: action.payload };
      case 'setShowModalAprobar':
        return { ...state, modalAprobar: action.payload };
      default:
        break;
    }
};

const createNotification  = (type) => {
  switch (type) {
    case 'eliminada':
      return NotificationManager.info('La tarjeta se ha eliminado.', 'Tarjeta eliminada', 5000);
    case 'rechazada':
      return NotificationManager.info('La tarjeta se ha rechazado.', 'Tarjeta rechazada', 5000);
    case 'aprobada':
      return NotificationManager.info('La tarjeta se ha aprobado.', 'Tarjeta aprobada', 5000);
    case 'restaurada':
      return NotificationManager.info('La tarjeta se ha restaurado.', 'Tarjeta restaurada', 5000);
    default:
      break;
  }
};

const initialState = {
    card: {
      id: 0,
      folio: '',
      id_estatus: 1,
      estatus: '',
      createdAt: '2019-11-11T19:16:51.000Z',
      numero_dia: 0,
      semana: 0,
      uid: '',
      num_trabajadores: 1,
      horario: {},
      dia: '',
      supervisor: {id: 0, nombre: ''},
      cuadrillero: {id: 0, nombre: ''},
      tipoActividad: { id: 0, nombre: ''},
      apuntador: {id: 0, nombre: ''},
      lotes: [],
      trabajadores: [],
      actividades: [],
      cultivos: [],
    },
    modalAprobar: false,
    modalRechazarShow: false,
};

const DetalleTarjetaTrabajo = props => {
    const api = useApi();
    const id = props.match.params;
    const { card: preloadedCard } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState, () => {
        if (preloadedCard) {
          return {
            ...initialState,
            card: preloadedCard,
          };
        }
        return initialState;
    });

    
  const { card, showModal, modalRechazarShow } = state;

    React.useEffect(() => {
        if (!preloadedCard) {
          api.get("/tarjeta/"+id.id, {})
          .then(response => {
            if (response.error) {
            } else {
              dispatch({ type: 'setCard', payload: response });
            }
          })
          .catch()
          .finally(response => {
          });
        }
      }, [id, preloadedCard, api]);


      const handleCardCancel = () => {
        ConfirmModal({
          title: (card.estatus === "Sincronizada") ? "Eliminar tarjeta" : "Restaurar tarjeta",
          message: <p style={{ textAlign: "center" }}>
            {card.estatus === "Sincronizada" && (<span>¿Esta seguro que desea eliminar esta tarjeta?</span>
            )}
            {card.estatus === "Eliminada" && (<span>¿Esta seguro que desea restaurar esta tarjeta?</span>
            )}
          </p>,
          buttons: [
            {
              label: 'Cancelar',
              class: 'btn-danger',
              onClick: () => null,
            },
            {
              label: 'Confirmar',
              class: 'btn-success',
              onClick: () => doCancel(),
            },
          ]
        });
    
        async function doCancel() {
          apiEliminarTarjeta(id)
            .then(response => {
              if (response.error) {
                NotificationManager.error(response.message, '', 5000);
              } else {
                if(card.estatus === "Sincronizada"){
                  dispatch({ type: 'setCard', payload: { ...card, id_estatus: 3, estatus: "Eliminada" } });
                  createNotification('eliminada');
                }else{
                  dispatch({ type: 'setCard', payload: { ...card, id_estatus: 1, estatus: "Sincronizada" } });
                  createNotification('restaurada');
                }
                
                api.get("/tarjeta/"+id.id, {})
                .then(response => {
                  if (response.error) {
                  } else {
                    dispatch({ type: 'setCard', payload: response });
                  }
                })
                .catch()
                .finally(response => {
                });
              }
            })
            .catch(() => {
              NotificationManager.error('Ocurrió un error. Inténtelo más tarde.', '', 5000);
            })
            .finally(() => {
            })
        }
      }

      const apiEliminarTarjeta = async (id) => {
          let response = { error: true, message: 'Hubo un error preparando la consulta' };

          response = await api.put("/tarjeta/eliminar?id="+id.id, {})
      
          if (response.error) {
              NotificationManager.error(response.message, '', 5000);
          } else {
              
          }

          return response;
      }

      const handleShowModalRechazar = () => {
        dispatch({ type: 'setModalRechazarShow', payload: !modalRechazarShow });
      };

      const handleModalApprove = () => {
        console.log('handle');
        dispatch({ type: 'setModalShow', payload: !showModal });
      };


      const confirmIncidencia = comentario => {
        apiRechazarTarjeta(id, comentario).then(response => {
          if (response.error) {
          } else {
            createNotification('rechazada');
            api.get("/tarjeta/"+id.id, {})
            .then(response => {
              if (response.error) {
              } else {
                dispatch({ type: 'setCard', payload: response });
                handleShowModalRechazar();
              }
            })
            .catch()
            .finally(response => {
            });
          }
        });
      };

      const apiRechazarTarjeta = async (id, obj) => {
          debugger;
          let response = { error: true, message: 'Hubo un error preparando la consulta' };

          response = await api.put("/tarjeta/rechazar?id="+id.id, obj)
      
          if (response.error) {
              NotificationManager.error(response.message, '', 5000);
          } else {
              
          }

          return response;
      }

      const confirmAprobar = () => {
        debugger;
        apiAprobarTarjeta(id).then(response => {
          if (response.error) {
            console.log("no se pudo aprobar")
          } else {
            dispatch({ type: 'setCard', payload: { ...card, id_estatus: 2, estatus: "Aprobada" } });
            createNotification('aprobada');
            api.get("/tarjeta/"+id.id, {})
            .then(response => {
              if (response.error) {
              } else {
                dispatch({ type: 'setCard', payload: response });
                handleModalApprove();
              }
            })
            .catch()
            .finally(response => {
            });
          }
        });
      };

      const apiAprobarTarjeta = async (id) => {
        debugger;
        let response = { error: true, message: 'Hubo un error preparando la consulta' };

        response = await api.put("/tarjeta/aprobar?id="+id.id, {})
    
        if (response.error) {
            NotificationManager.error(response.message, '', 5000);
        } else {
            
        }

        return response;
    }
    

      return (
        <div>
          {!preloadedCard && (
            <div className="row no-pd-row separate">
              <div className="col-md-5 col-12">
                <h3>
                  <FontAwesomeIcon icon={faArrowLeft} className="fa-icon" onClick={() => props.history.goBack()}/> Detalles de tarjeta
                </h3>
              </div>
              <div className="col-md-7 col-12 tar">
                {card.estatus === "Sincronizada" && (
                  <div>
                    <button className="btn btn-lgt btn-danger" onClick={handleCardCancel}>
                    <FontAwesomeIcon icon={faTrash} className="fa-icon" /> Eliminar tarjeta
                    </button>
                    <button className="btn btn-lgt btn-warning" onClick={handleShowModalRechazar}>
                    <FontAwesomeIcon icon={faTimes} className="fa-icon" /> Rechazar tarjeta
                    </button>
                    <button className="btn btn-success btn-lgt" onClick={handleModalApprove}>
                    <FontAwesomeIcon icon={faCheck} className="fa-icon" /> Aprobar tarjeta
                    </button>
                  </div>  
                )}
                {card.estatus === "Eliminada" && (
                  <div>
                    <button className="btn btn-lgt btn-success" onClick={handleCardCancel}>
                    <FontAwesomeIcon icon={faSync} className="fa-icon" /> Restaurar tarjeta
                    </button>
                  </div>  
                )}
              </div>
            </div>
          )}
          <InfoTarjeta
            card={state}
          />
          {card.tipoActividad.nombre !== "Por cuadro" && (<ListaTrabajoTarjeta
            listaTrabajo={card.lista_trabajo}
            tipoActividad={card.tipoActividad}
          />)}
            
          <ActividadesTarjeta
            actividades={card.actividades}
          />
          <LotesTarjeta
            id={id}
            lotes={card.lotes}
          />
          <CultivosTarjeta 
            cultivos={card.cultivos} 
          />
          <TrabajadoresTarjeta
            trabajadores={card.trabajadores}
            tipoActividad={card.tipoActividad}
          />
          {!preloadedCard && (
            <ComentariosTarjeta 
              id={id} 
              NotificationManager={NotificationManager} 
              editable={true} 
            />
          )}

          <ModalRechazar
            show={modalRechazarShow}
            confirm={confirmIncidencia}
            handleClose={handleShowModalRechazar}
          />
          
          <ModalAprobar 
            show={showModal} 
            confirm={confirmAprobar} 
            handleClose={handleModalApprove} 
          />

          <NotificationContainer/>
        </div>

        
      );
};

export default DetalleTarjetaTrabajo;