import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style-tarjetaTrabajo.scss';


moment.updateLocale('es', {
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
});

const TarjetasTrabajoCard = ({ card, onButtonClick }) => {
  const cardStatus = {
        1: 'Sincronizada',
        2: 'Aprobada',
        3: 'Rechazada',
        4: 'Eliminada',
    };

  return (
    <div className="col-12 col-md-4 separate sizeDiv">
      <div className="card agrocard">
        <div className="card-body">
          <div className="row no-pd-row">
            <label className="col-md-8 lbl-card-title">
              <b>{moment(card.createdAt).format('DD/MMM/YYYY')}</b>
            </label>
            <label className="col-md-4 tar lbl-card-title">
              <b>Semana {card.semana}</b>
            </label>
          </div>

          <div className="row no-pd-row">
            <label className="col-md-9 lbl-card-title">
              Folio:<b> {card.folio}</b>
            </label>

            <label className="col-md-3 tar lbl-card-title">
              {card.trab_manual === 1 && (
                <label title="Trabajadores manuales" className="badge badge-danger">
                  <i className="fas fa-exclamation-triangle" />{' '}
                </label>
              )}
              {card.modificado === 1 && (
                <label title="La tarjeta se ha modificado" className="badge badge-warning">
                  <i className="fas fa-exclamation-triangle" />{' '}
                </label>
              )}
            </label>
          </div>

          <hr />

          <div className="row no-pd-row">
            <div className="col-md-6">
              <label className="lbl-card-title lblstatus">Estado</label>
              <label className="status">{cardStatus[card.id_estatus]}</label>
            </div>
            <div className="col-md-6">
              <label className="lbl-card-title">Turno</label>
              <p>{card.horario}</p>
            </div>
          </div>

          <div>
            <label className="lbl-card-title">Supervisor</label>
            <p>{card.supervisor}</p>
          </div>

          <div className="row no-pd-row">
            <div className="col-md-5">
              <label className="lbl-card-title">Actividades</label>
              <p className="cut">{card.actividades}</p>
            </div>
            <div className="col-md-7">
              <label className="lbl-card-title">No. de trabajadores</label>
              <p>{card.trabajadores}</p>
            </div>
          </div>

          <div>
            <label className="lbl-card-title">Lotes</label>
            <p className="cut">{card.lotes}</p>
          </div>

          <div>
            <label className="lbl-card-title">Cultivos</label>
            <p className="cut">{card.cultivos}</p>
          </div>

          <div>
            <button onClick={() => onButtonClick(card.id)} className={`btn btn-lgt w-100 btn-primary`}>
              Detalles
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TarjetasTrabajoCard.propTypes = {
  card: PropTypes.any,
  onButtonClick: PropTypes.func,
};

TarjetasTrabajoCard.defaultProps = {
  card: {},
  onButtonClick: f => f,
};

export default TarjetasTrabajoCard;
