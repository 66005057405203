import { Redirect, useRouteMatch, Switch, Route } from "react-router-dom";
import Login from "scenes/auth/login";

const RouterAuth = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={`${url}`} to={`${url}/login`} />
      <Route exact path={`${url}/login`} component={Login} />
    </Switch>
  );
};

export default RouterAuth;
