import React from 'react';
import { Modal } from 'react-bootstrap';


const ModalAprobar = (props) => {
    const { show, handleClose, confirm } = props;



    const handleCloseModal = () => {
        handleClose();
    }


    const continuar = () => {
            confirm();
            handleCloseModal();
    }

    return (
        <Modal show={show} onHide={handleCloseModal} centered
            dialogClassName="modal-20w">
            <Modal.Header>
                <Modal.Title>Confirmar aprobación de tarjeta</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="tac">
                    <label>Está a punto de aprobar la tarjeta</label>
                </div>
                <hr />
                <div className="tac separate">
                    <button className="btn btn-danger btn-lgt" onClick={handleCloseModal}>Cancelar</button>
                    <button className="btn btn-lgt btn-success" onClick={continuar} >Aprobar</button>
                </div>

            </Modal.Body>
        </Modal>
    );
}

ModalAprobar.defaultProps = {
    show: false,
    name: '',
    type: 'desactivar',
    typeUser: '',
    confirm: () => { },
    handleClose: () => { },
}

export default ModalAprobar;