import { Redirect, useRouteMatch, Switch, Route } from "react-router-dom";
import Catalogs from "scenes/catalogs/catalogs";
import Users from "scenes/users/users";
import RoutesTarjetasTrabajo from "../components/forms/tarjetasTrabajo/routesTarjetasTrabajo";

const RouterPanel = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={`${url}`} to={`${url}/monitor`} />
      <Route path={`${url}/monitor`} component={null} />
      <Route path={`${url}/usuarios`} component={Users} />
      <Route path={`${url}/catalogos`} component={Catalogs} />
      <Route path={`${url}/tarjetasTrabajo`} component={RoutesTarjetasTrabajo}/>
    </Switch>
  );
};

export default RouterPanel;
