import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { faLongArrowAltLeft, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "utils/hooks/useApi";
import { useLoader } from "utils/context/loaderContext";
import InfoColumn from "components/ui/infoColumn/infoColumn";
import { LocalTable } from "@wafo/table";

const DetailsCrop = () => {
  const api = useApi();
  const [loader] = useLoader();

  const { id: idCrop } = useParams();
  const history = useHistory();

  const [crop, setCrop] = useState({});

  const getData = useCallback(() => {
    if (!idCrop) return;
    loader.set();
    api
      .get("cat/cult", { params: { id: idCrop } })
      .then(res => {
        
        res.variedades = res.variedades.map(variedad => ({ ...variedad, activo: { activo : variedad.activo, id : variedad.id }}));
        console.log('Valor antes ', res.variedades);
        setCrop(res);
        
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idCrop, loader]);

  /*const toggleActive2 = useCallback(
    idVar => {
      debugger;
      loader.set();
      api
        .put("/cat/var/status", null, {
          params: {
            id: idVar,
          },
        }).then(res => {
          onAfterSubmit();
        })
        .catch(console.error)
        .finally(() => {
          
          loader.stop();
        });
    },
  );*/

  const toggleActive = async (idVar) => {
    loader.set();
    try {
      await api.put("/cat/var/status", null, { params: { id: idVar, } });
      await api
      .get("cat/cult", { params: { id: idCrop } })
      .then(res => {
        res.variedades = res.variedades.map(variedad => ({ ...variedad, activo: { activo : variedad.activo, id : variedad.id }}));
        console.log('Valor antes ', res.variedades);
        setCrop(res);
      })
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  }

  useEffect(getData, [getData]);

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="left">
              <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </button>
              <h5>Información de cultivo</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <InfoColumn
                colSize="col-12 col-md-4"
                title="Actividad"
                info={
                  <Fragment>
                    <span className="me-2">#{crop?.id}</span>
                    <span className={`badge ${crop?.activo ? "bg-success" : "bg-danger"}`}>
                      <FontAwesomeIcon icon={crop?.activo ? faToggleOn : faToggleOff} />
                    </span>
                  </Fragment>
                }
              />
              <InfoColumn colSize="col-12 col-md-4" title="Nombre" info={crop?.nombre} />
            </div>
            <div className="row mt-3">
              <LocalTable
                locale="es"
                wrapperClass="table-no-header table-no-pagination"
                tableClass="table table-sm table-striped table-borderless"
                tableWrapperClass="table-wrapper"
                columns={["ID", "Nombre de variedad", "Opciones"]}
                rows={crop?.variedades}
                noRowsMessage="Sin variedades registradas"
                keepPage={false}
                updateTable={f => f}
                columnsConfig={{
                  id: val => <span>#{val}</span>,
                  activo: val2 => (
                    <button
                    type="button"
                    className={`btn btn-sm ${val2.activo ? "btn-success" : "btn-danger"}`}
                    title="Habilitar / Inhabilitar"
                    onClick={() => toggleActive(val2.id)}
                  >
                    <FontAwesomeIcon icon={val2.activo ? faToggleOn : faToggleOff} />
                  </button>)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCrop;
