import { faFolderOpen, faUsers, faClipboardList, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useReducer, createContext, useContext, useMemo } from "react";

const UIContext = createContext();

const initialState = {
  sidebar: false,
  menuLinks: [
    {
      id: "001",
      title: "Tarjetas de trabajo",
      icon: faClipboardList,
      path: "/panel/tarjetasTrabajo",
    },
    {
      id: "002",
      title: "Usuarios",
      icon: faUsers,
      path: "/panel/usuarios",
    },
    {
      id: "003",
      title: "Catálogos",
      icon: faFolderOpen,
      path: "/panel/catalogos",
    },
  ],
};

function reducer(state, action) {
  switch (action.type) {
    case "UI_SIDEBAR_TOGGLE":
      return {
        ...state,
        sidebar: !state.sidebar,
      };
    default:
      throw new Error("No type on action for AlertContext");
  }
}

function UIProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <UIContext.Provider value={{ state, dispatch }} {...props} />;
}

const useUI = () => {
  const { state, dispatch } = useContext(UIContext);

  const actions = useMemo(
    () => ({
      toggleSidebar: () => dispatch({ type: "UI_SIDEBAR_TOGGLE" }),
    }),
    [dispatch],
  );

  return [actions, state];
};

export { UIProvider, useUI };
