import React from "react";
import { Modal } from 'react-bootstrap';


const ModalRechazar = (props) => {
    const { show, confirm, handleClose } = props;
    const [comentario, setComentario] = React.useState('');

    const HandleClose = () => {
        handleClose();
    }

    const Confirm = () => {
        confirm(comentario);
        HandleClose();
    }

    const handleChangeComment = (e) => {
        setComentario(e.target.value);
    }

    return (<Modal show={show} onHide={HandleClose} centered
        dialogClassName="modal-20w"
    >
        <Modal.Header>
            <Modal.Title>Rechazar tarjeta</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
            <div className="">
                <p>{'Estas a punto de rechazar la tarjeta'}</p>
                <label>Ingresa comentario</label>
                <textarea className="form-control" value={comentario} onChange={handleChangeComment}></textarea>
            </div>
            <div className="modal-footer">
                <button className="btn btn-danger btn-lgt" onClick={HandleClose}>Cancelar</button>
                <button className="btn btn-success btn-lgt" onClick={Confirm}>{"Rechazar"}</button>
            </div>
        </Modal.Body>
    </Modal>)
}

export default ModalRechazar;