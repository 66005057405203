
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const LotesTarjeta = (props) => {
    
    const { lotes } = props;
    const [lotesList, setLotesList] = React.useState(lotes);

    React.useEffect(() => {
        setLotesList(lotes);
    }, [lotes]);

    return (
    <div className="card separate">
        <div className="card-body">
            <div className="row no-row-margin">
                <div className="col-md-6 col-12">
                    <h5><FontAwesomeIcon icon={faIdCard} className="fa-icon" /> Lotes</h5>
                </div>
            </div>
            <div >
            <hr />
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Número</th>
                                <th>Nombre</th>
                                <th>Hectáreas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lotesList.map(elm =>
                                <tr key={elm.id} className={elm.toEliminate ? 'to-eliminate' : ''}>
                                    <td>{elm.id}</td>
                                    <td>{elm.nombre}</td>
                                    <td>{elm.hectareas}</td>                                    
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>);

}

LotesTarjeta.defaultProps = {
    lotes: []
}

export default LotesTarjeta;