import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const SectionHeader = ({ showBack, leftContent, rightContent }) => {
  const history = useHistory();

  return (
    <div className="section-header">
      <div className="left">
        {showBack && (
          <button type="button" className="btn btn-link back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </button>
        )}
        {leftContent}
      </div>
      <div className="right">{rightContent}</div>
    </div>
  );
};

export default SectionHeader;
