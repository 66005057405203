import { Fragment } from "react";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHeader from "components/ui/sectionHeader/sectionHeader";
import NavButttons from "components/ui/navButtons/navButtons";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import TableAdmins from "./admins/tableAdmins";
import TableAppointers from "./appointers/tableAppointers";
import RegisterAppointer from "./appointers/registerAppointer";
import RegisterAdmin from "./admins/registerAdmin";

const Users = () => {
  const { url } = useRouteMatch();

  return (
    <div>
      <SectionHeader
        leftContent={
          <Fragment>
            <FontAwesomeIcon icon={faUsers} className="fa-icon" />
            <h2>Usuarios</h2>
          </Fragment>
        }
      />
      <NavButttons
        buttons={[
          { path: `${url}/apuntadores`, title: "Apuntadores" },
          { path: `${url}/administradores`, title: "Administradores" },
        ]}
      />
      <Switch>
        <Redirect exact from={`${url}`} to={`${url}/apuntadores`} />
        <Redirect exact from={`${url}/apuntadores`} to={`${url}/apuntadores/tabla`} />
        <Route exact path={`${url}/apuntadores/registro`} component={RegisterAppointer} />
        <Route exact path={`${url}/apuntadores/edicion/:id`} component={RegisterAppointer} />
        <Route exact path={`${url}/apuntadores/detalles/:id`} component={null} />
        <Route exact path={`${url}/apuntadores/tabla`} component={TableAppointers} />
        <Redirect exact from={`${url}/administradores`} to={`${url}/administradores/tabla`} />
        <Route exact path={`${url}/administradores/registro`} component={RegisterAdmin} />
        <Route exact path={`${url}/administradores/edicion/:id`} component={RegisterAdmin} />
        <Route exact path={`${url}/administradores/detalles/:id`} component={null} />
        <Route exact path={`${url}/administradores/tabla`} component={TableAdmins} />
      </Switch>
    </div>
  );
};

export default Users;
