import { useCallback, useRef, useState } from "react";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "@wafo/table";
import { useHistory, useRouteMatch, useParams } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import useQuery from "utils/hooks/useQuery";
import React from "react";
import { Link } from "react-router-dom";
import './logs.css';
import moment from 'moment';

const LogActividadesArbol = () => {
  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  const { id: idArbol } = useParams();
  const [loader] = useLoader();
  const api = useApi();

  const tableRef = useRef();
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const getData = useCallback(
    async ({ size, page }) => {
      loader.set();
      api
        .get("/cat/lote/arbol/arboles?id="+idArbol+"&page="+page+"&limit="+size, {})
        .then(resp => {
          setRows(
            resp.rows.map(row => ({
              folio: {folio: row.folio, id: row.id_tarjeta },
              fecha_creacion: moment(row.fecha_creacion).format('YYYY-MM-DD h:mm:ss a'),
              registro: row.registro,
            })),
          );
          setTotalRows(resp.count);

          history.replace({
            pathname: match.url,
          });
        })
        .catch(console.error)
        .finally(loader.stop);
    },
    [loader, api, idArbol, history, match.url],
  );

  return (
      <React.Fragment>
        <div className="row no-row-margin">
            <div className="col-md-6 col-12">
                <h5>
                    <FontAwesomeIcon icon={faIdCard} className="fa-icon" /> Log por actividades de árbol
                </h5>
            </div>
        </div>
        <DataTable
        ref={tableRef}
        locale="es"
        columns={["Folio", "Fecha creación", "Registro"]}
        rows={rows}
        totalRows={totalRows}
        tableClass="table table-sm table-striped table-borderless"
        wrapperClass="card-table no-busqueda"
        tableWrapperClass="wafotable-wrapper"
        onPagination={getData}
        noRowsMessage="Sin registros por mostrar"
        initialPage={parseInt(query.get("page"), 10)}
        columnsConfig={{
          folio: val => (
            <span>
                <Link to={"/panel/tarjetasTrabajo/detalle/"+val.id}>{val.folio}</Link>
            </span>
          )
        }}
        />
      </React.Fragment>
  );
};

export default LogActividadesArbol;
