import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import "react-datepicker/dist/react-datepicker.css";
import WafoDateTime from './datetime';
import './tarjetasTrabajo.css';
import useApi from "utils/hooks/useApi";
import { useLoader } from "utils/context/loaderContext";

const TarjetasTrabajoFilters = ({onFilter, onClear, initialFilters}) => {
    
    const [formKey, setFormKey] = useState(Math.random());
    const api = useApi();
    const [loader] = useLoader();
    const [horario, setHorario] = useState([]);


    const handleClear = () => { 
        setFormKey(Math.random());
        onClear();
    };

    const handleSubmit = (form, values) => {
        if (form.valid) {
            onFilter(values);
        }
    };

    //Get lineas por lote id y precargar Linea A
    useEffect(() => {  
        loader.set();
        api
          .get("/tarjeta/horarios", {})
          .then(resp => {
            resp = resp.map(horario => ({ value: horario.id, display: horario.descripcion }));    
            console.log(resp);
            setHorario(resp);
          })
          .catch(console.error)
          .finally(loader.stop);
      }, [api, loader]);

    return (
        <div className="card separate">
            <div className="card-body" style={{ paddingBottom: 0 }}>
                <WafoForm key={formKey} formId="form-material" locale="es" onSubmit={handleSubmit} values={initialFilters}> 
                <WafoFormSelect
                    name="type"
                    defaultValue="Tipo de búsqueda"
                    customClass="col-3 col-md-3"
                    options={[
                    { value: 'supervisor', display: 'Supervisor' },
                    { value: 'apuntador', display: 'Apuntador' },
                    { value: 'trabajador', display: 'Trabajador' },
                    { value: 'lote', display: 'Lote' },
                    { value: 'actividad', display: 'Actividad' },
                    ]}
                    extraProps={{
                    className: 'form-control select-input-append',
                    }}
                    validations={{ required: false }}
                />
                <WafoFormInput
                    type="text"
                    name="search"
                    placeholder="Buscar en tarjetas"
                    customClass="col-9 col-md-9"
                    extraProps={{
                    className: 'form-control append-input',
                    }}
                    validations={{ required: false, maxLength: 254 }}
                />
                <WafoFormSelect
                    name="id_horario"
                    defaultValue="Seleccione horario"
                    customClass="col-3 col-md-3"
                    options={horario}
                    extraProps={{
                    className: 'form-control limit',
                    }}
                    validations={{ required: false }}
                />
                <WafoDateTime name="fecha_inicial" customClass="col-2 col-md-2" handleChange />

                <WafoDateTime name="fecha_final" customClass="col-2 col-md-2" handleChange />
                
                <div className={`col-12 col-md-2 filter-buttons`}>
                    <button className={'btn btn-primary'} onClick={handleClear}>
                    Limpiar
                    </button>
                    <button className={'btn btn-success'} onClick={f => f}>
                    Buscar
                    </button>
                </div>
                </WafoForm>
            </div>
        </div>
    )
}

TarjetasTrabajoFilters.propTypes = {
    onFilter: PropTypes.func,
    onClear: PropTypes.func,
    initialFilters: PropTypes.any
  };
  
  TarjetasTrabajoFilters.defaultProps = {
    onFilter: f => f,
    onClear: f => f,
    initialFilters: {}
};

export default TarjetasTrabajoFilters;