import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useLocation, useRouteMatch } from "react-router";
import { faLongArrowAltLeft, faToggleOff, faToggleOn, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "utils/hooks/useApi";
import { useLoader } from "utils/context/loaderContext";
import InfoColumn from "components/ui/infoColumn/infoColumn";
import { LocalTable } from "@wafo/table";
import useQuery from "utils/hooks/useQuery";
import moment from "moment";
const DetailsBatch = () => {
  const api = useApi();
  const match = useRouteMatch();
  const [loader] = useLoader();
  const location = useLocation();
  const { id: idBatch } = useParams();
  const history = useHistory();
  const query = useQuery();
  const [batch, setBatch] = useState({});
  const [linea, setLinea] = useState({});
  const [arbol, setArbol] = useState([]);
  const [lineaSeleccionada, setLineaSeleccionada] = useState();

  const handleChange = e => getArboles(e.target.value);

  const getData = useCallback(() => {
    if (!idBatch) return;
    loader.set();
    api
      .get("cat/lote", { params: { id: idBatch } })
      .then(setBatch)
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idBatch, loader]);

  //Get lineas por lote id y precargar Linea A
  useEffect(() => {
    loader.set();
    debugger;
    api
      .get("/cat/lote/lineas?id=" + idBatch, {})
      .then(resp => {
        console.log("valor del query: ", query.get("linea"));
        setLinea(resp);
        console.log(resp);
        setLineaSeleccionada(query.get("linea"));
        api
          .get(
            "/cat/lote/arbol?id=" +
              idBatch +
              "&linea=" +
              (query.get("linea") !== null ? query.get("linea") : resp[0].linea),
            {},
          )
          .then(res => {
            res = res.rows.map(arbol => ({
              id: arbol.id,
              nombre: arbol.nombre,
              estatusArbol: {
                activo: arbol.activo,
                id_estatus: arbol.id_estatus,
                estatus_arbol: arbol.estatus_arbol,
                id: arbol.id,
              },
              activo: {
                activo: arbol.activo,
                id_estatus: arbol.id_estatus,
                estatus_arbol: arbol.estatus_arbol,
                id: arbol.id,
              },
            }));

            api.get("/cat/lote/estatus", {}).then(res => {
              res = res.map(estatusArbol => ({ id: estatusArbol.id, descripcion: estatusArbol.descripcion }));
            });

            setArbol(res);
          });
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idBatch, loader]);

  const getArboles = async linea => {
    loader.set();
    setLineaSeleccionada(linea);

    try {
      await api.get("/cat/lote/arbol?id=" + idBatch + "&linea=" + linea, {}).then(res => {
        res = res.rows.map(arbol => ({
          id: arbol.id,
          nombre: arbol.nombre,
          estatusArbol: {
            activo: arbol.activo,
            id_estatus: arbol.id_estatus,
            estatus_arbol: arbol.estatus_arbol,
            id: arbol.id,
          },
          activo: {
            activo: arbol.activo,
            id_estatus: arbol.id_estatus,
            estatus_arbol: arbol.estatus_arbol,
            id: arbol.id,
          },
        }));
        getEstatusArbol();
        setArbol(res);
      });
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  const getEstatusArbol = async () => {
    loader.set();
    try {
      await api.get("/cat/lote/estatus", {}).then(res => {
        res = res.map(estatusArbol => ({ id: estatusArbol.id, descripcion: estatusArbol.descripcion }));
      });
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  const updateActivoInactivoArbol = async idArbol => {
    console.log(idArbol);
    loader.set();

    try {
      await api.put("/cat/lote/arbol/status?id=" + idArbol, {}).then(res => {
        console.log(res.message);
      });
    } catch (error) {
      console.error(error);
    }
    getArboles(lineaSeleccionada);
  };

  const goToDetails = id => {
    history.replace({
      pathname: location.pathname,
      search: `?linea=${lineaSeleccionada}`,
    });
    history.push(`arbol/detalleArbol/${id}`);
  };

  useEffect(getData, [getData]);

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="left">
              <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </button>
              <h5>Información de lote</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <InfoColumn
                colSize="col-12 col-md-4"
                title="Actividad"
                info={
                  <Fragment>
                    <span className="me-2">#{batch?.id}</span>
                    <span className={`badge ${batch?.activo ? "bg-success" : "bg-danger"}`}>
                      <FontAwesomeIcon icon={batch?.activo ? faToggleOn : faToggleOff} />
                    </span>
                  </Fragment>
                }
              />
              <InfoColumn colSize="col-12 col-md-4" title="Nombre" info={batch?.nombre_lote} />
              <InfoColumn colSize="col-12 col-md-4" title="Nomenclatura" info={batch?.nomenclatura} />
              <InfoColumn colSize="col-12 col-md-4" title="Cultivo" info={batch?.nombre_cultivo} />
              <InfoColumn colSize="col-12 col-md-4" title="Sección" info={batch?.nombre_seccion} />
              <InfoColumn colSize="col-12 col-md-4" title="Hectareas" info={batch?.hectareas} />
              <InfoColumn
                colSize="col-12 col-md-4"
                title="Fecha Lote"
                info={moment(batch?.fecha_lote).utc().format("DD-MM-YYYY").toString()}
              />
              <InfoColumn colSize="col-12 col-md-4" title="Western" info={batch?.western} />
              <InfoColumn colSize="col-12 col-md-4" title="Wichita" info={batch?.wichita} />
              <InfoColumn colSize="col-12 col-md-4" title="Navajo" info={batch?.navajo} />
            </div>

            <div className="col">
              <div className="form-divider">
                <h5>Filtrar árboles por linea</h5>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <select className="form-control" id="selectLinea" onChange={e => handleChange(e)}>
                    {Object.entries(linea).map(([uuid, v]) => (
                      <option selected={v.linea === lineaSeleccionada} key={uuid} value={v.linea}>
                        {v.linea}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <LocalTable
                locale="es"
                wrapperClass="table-no-header table-no-pagination"
                tableClass="table table-sm table-striped table-borderless"
                tableWrapperClass="table-wrapper"
                columns={["ID", "Nombre de árbol", "Estatus árbol", "Acciones"]}
                rows={arbol}
                noRowsMessage="Sin árboles registrados"
                keepPage={false}
                updateTable={f => f}
                columnsConfig={{
                  id: val => <span>#{val}</span>,
                  estatusArbol: val3 => <span>{val3.estatus_arbol}</span>,
                  activo: val2 => (
                    <div className={"btn-row"}>
                      <button
                        type="button"
                        className={`btn btn-sm ${val2.activo ? "btn-success" : "btn-danger"}`}
                        title="Habilitar / Inhabilitar"
                        onClick={() => updateActivoInactivoArbol(val2.id)}
                      >
                        <FontAwesomeIcon icon={val2.activo ? faToggleOn : faToggleOff} />
                      </button>
                      <button
                        type="button"
                        className={`btn btn-sm btn-primary`}
                        title="Detalles"
                        onClick={() => goToDetails(val2.id)}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </button>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsBatch;
