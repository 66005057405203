import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "assets/logo-default.png";
import { NavLink } from "react-router-dom";
import { useUI } from "utils/context/uiContext";

const MobileSidebar = () => {
  const [, { menuLinks }] = useUI();

  return (
    <nav className="mobile-sidebar navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {menuLinks.map(link => (
              <li key={link.id} className="nav-item">
                <NavLink to={link.path} className="nav-link" activeClassName="active">
                  <FontAwesomeIcon className="fa-icon" icon={link.icon} />
                  <span>{link.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileSidebar;
