import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput } from "@wafo/forms";
import { setToken } from "utils/auth/authService";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import { NotificationManager, NotificationContainer } from 'react-notifications';

const initialValues = {
  user: "",
  password: "",
};

const FormLogin = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();

  const onSubmit = (form, values) => {
    if (!form.valid) return;
    loader.set();
    api
      .post("/auth/login", { ...values })
      .then(resp => {
          setToken(resp.token);
          onAfterSubmit()
      })
      .catch(() => {
        NotificationManager.error('Usuario o contraseña inválida.', 'Error al iniciar sesión', 5000);
      })
      .finally(() => loader.stop());
  };

  return (
    <WafoForm formId="form-login" locale="es" onSubmit={onSubmit} values={initialValues}>
      <WafoFormInput
        type="text"
        name="user"
        label="Nombre de usuario"
        placeholder="Ingrese su usuario"
        customClass="col-12"
        validations={{
          required: true,
        }}
      />

      <WafoFormInput
        type="password"
        name="password"
        label="Contraseña"
        placeholder="Ingrese su contraseña"
        customClass="col-12"
        validations={{ required: true }}
      />

      <div className="d-grid">
        <button type="submit" form="form-login" className="btn btn-primary">
          <FontAwesomeIcon icon={faSignInAlt} className="fa-icon" />
          <span>Iniciar sesión</span>
        </button>
      </div>

      <NotificationContainer/>
    </WafoForm>
  );
};

export default FormLogin;
