import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormWorker from "components/forms/catalogs/formWorker";
import { useHistory } from "react-router";

const RegisterWorker = () => {
  const history = useHistory();

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="left">
              <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </button>
              <h5>Información de actividad</h5>
            </div>
          </div>
          <div className="card-body">
            <FormWorker onAfterSubmit={() => history.goBack()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterWorker;
