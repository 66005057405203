import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const TrabajadoresTarjeta = props => {
    const { trabajadores, tipoActividad } = props;
    const [trabajadoresList, setTrabajadoresList] = React.useState(trabajadores);

    React.useEffect(() => {
        setTrabajadoresList(trabajadores);
    }, [ trabajadores]);

    return (
        <div className="card separate">
            <div className="card-body">
                <div className="row no-row-margin">
                    <div className="col-md-6 col-12">
                        <h5>
                            <FontAwesomeIcon icon={faIdCard} className="fa-icon" /> Trabajadores
                        </h5>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                {tipoActividad.nombre !== "Por línea" && 
                                <tr>
                                    <th>No</th>
                                    <th>Nombre</th>
                                </tr>
                                }
                                {tipoActividad.nombre === "Por línea" && 
                                <tr>
                                    <th>No</th>
                                    <th>Nombre</th>
                                    <th>Última línea </th>
                                    <th>Último árbol </th> 
                                </tr>
                                }
                            </thead>
                            <tbody>
                                {trabajadoresList.map(worker => { 
                                    return (
                                    <React.Fragment>
                                        {tipoActividad.nombre === "Por línea" &&
                                            <tr key={worker.id} style={{ backgroundColor: "#EBEAEA" }}>
                                                <td>{worker.id}</td>
                                                <td>{worker.nombre}</td>
                                                <td>{worker.ultimaLinea === null ? "" : worker.ultimaLinea.linea}</td>
                                                <td>{worker.ultimoArbol === null ? "" : worker.ultimoArbol.nombre}</td>
                                            </tr>
                                        }
                                        {tipoActividad.nombre !== "Por línea" &&
                                            <tr key={worker.id} style={{ backgroundColor: "#EBEAEA" }}>
                                                <td>{worker.id}</td>
                                                <td>{worker.nombre}</td>
                                            </tr>
                                        }
                                        
                                        {tipoActividad.nombre === "Por árbol" && worker.arboles.map((arboles, i) =>
                                            <tr key={i}>
                                                <td colSpan={2}>{'Árbol '+arboles.arbol.nombre}</td>
                                            </tr>
                                        )}
                                        {tipoActividad.nombre === "Por línea" && worker.linea.map((lineas, i) =>
                                            <tr key={i}>
                                                <td colSpan={4}>{'Línea '+lineas.linea}</td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default TrabajadoresTarjeta;