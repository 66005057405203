import { useMemo } from "react";
import axios from "axios";
import { getToken, isTokenExpired, refreshToken } from "utils/auth/authService";
import { useAlerts } from "utils/context/alertsContext";

async function setTokenInConfigHeader(config) {
  try {
    let token = await getToken();
    if (!token) throw new Error("Null token");
    if (isTokenExpired(token)) {
      token = await refreshToken();
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  } catch (error) {
    console.error("No token, trying temp in .env");
    config.headers["Authorization"] = `Bearer ${process.env.REACT_APP_TEMP_TOKEN}`;
    return config;
  }
}

const useApi = configuration => {
  const { autoAlerts = true } = configuration || {};
  const [alerts] = useAlerts();

  const api = useMemo(() => {
    const axiosApi = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 3000,
    });

    axiosApi.interceptors.request.use(async config => {
      await setTokenInConfigHeader(config);
      return config;
    });

    axiosApi.interceptors.response.use(
      async response => {
        if (response.headers["content-type"].indexOf("application/json") !== -1) {
          return response.data;
        }
        return response;
      },
      error => {
        const errorAlert = {};

        if (error.response) {
          // The request was made and the server responded with a status code.
          const {
            data: { type, message },
          } = error.response;
          errorAlert.message = (
            <p className="m-0">
              <span>{message || "Sin mensaje de error."}</span>
              <br />
              <span>Code: [{type || "Sin código de error."}]</span>
            </p>
          );
        } else if (error.request) {
          // The request was made but no response was received
          errorAlert.message = "Error inespereado. Revise su conexión a la red.";
        } else {
          // Something happened and the request failed.
          errorAlert.message = "Error inesperado. Intente de nuevo o contacte a soporte.";
        }

        if (autoAlerts) {
          alerts.error({
            type: "ALERT_ERROR",
            payload: errorAlert,
          });
        }

        return Promise.reject(error);
      },
    );

    return axiosApi;
  }, [alerts, autoAlerts]);

  return api;
};

export default useApi;
