import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WafoForm, WafoFormInput, WafoFormSelect } from "@wafo/forms";
import { LocalTable } from "@wafo/table";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useLoader } from "utils/context/loaderContext";
import useApi from "utils/hooks/useApi";
import FilePicker from "../customInputs/filePicker/filePicker";

import moment from "moment";
import WafoDateTime from "../tarjetasTrabajo/datetime";

const FormBatch = ({ onAfterSubmit = f => f }) => {
  const api = useApi();
  const [loader] = useLoader();
  const [fechalote, setFechalote] = useState("");

  const { id: idBatch } = useParams();
  const { state: locationState } = useLocation();

  const [batch, setBatch] = useState(() => {
    if (locationState && locationState.batch) {
      return locationState.batch;
    }
    return {};
  });
  const [lines, setLines] = useState([]);
  const [crops, setCrops] = useState([]);
  const [seccion, setSeccion] = useState([]);

  // Get crops
  useEffect(() => {
    loader.set();
    api
      .get("/cat/var/list", {
        params: {
          limit: 999999,
          page: 1,
        },
      })
      .then(resp => {
        setCrops(
          resp.rows.map(row => ({
            value: row.id,
            display: row.nombre_cultivo,
          })),
        );
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, loader]);

  //Get sección
  useEffect(() => {
    loader.set();
    api
      .get("/cat/lote/seccion", {})
      .then(resp => {
        setSeccion(
          resp.map(row => ({
            value: row.id,
            display: row.nombre,
          })),
        );
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, loader]);

  useEffect(() => {
    if (!idBatch) return;
    loader.set();
    api
      .get("cat/lote", { params: { id: idBatch } })
      .then(res => {
        setBatch(res);
      })
      .catch(console.error)
      .finally(loader.stop);
  }, [api, idBatch, loader]);

  const onSubmit = async (form, values) => {
    debugger;
    if (!form.valid) return;
    loader.set();
    try {
      if (!lines.length && !idBatch) {
        const formModel = new FormData();
        formModel.append("template", values.template);
        const resp = await api.post("/cat/lote/template", formModel);
        setLines(resp);
      } else {
        if (idBatch) {
          await api.put(
            "/cat/lote",
            {
              ...values,
              id_seccion: values.id_seccion,
            },
            { params: { id: idBatch } },
          );
        } else {
          await api.post("/cat/lote", {
            ...values,
            id_seccion: values.id_seccion,
            lineas: lines,
          });
        }
        onAfterSubmit();
      }
    } catch (error) {
      console.error(error);
    }
    loader.stop();
  };

  return (
    <WafoForm formId="form-template" locale="es" onSubmit={onSubmit} values={batch} ignoreEmpty>
      <WafoFormInput
        type="text"
        name="nombre"
        label="Nombre de lote"
        placeholder="Ingrese nombre"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormInput
        type="text"
        name="nomenclatura"
        label="Nomenclatura"
        placeholder="Ingrese nomenclatura"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormInput
        type="number"
        name="hectareas"
        label="Hectareas"
        placeholder="Ingrese hectareas"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
        }}
      />

      <WafoFormSelect
        name="id_cultivo"
        label="Cultivo"
        defaultValue="Seleccione cultivo"
        options={crops}
        customClass="col-12 col-md-4"
        validations={{ required: true }}
      />

      <WafoFormSelect
        name="id_seccion"
        label="Sección"
        defaultValue="Seleccione sección"
        options={seccion}
        customClass="col-12 col-md-4"
        validations={{ required: true }}
      />

      <div className="col-12 col-md-4">
        <label for="start" style={{ fontWeight: "600" }}>
          Start date:
        </label>
        {/*<input type="date" id="start" className="form-control" value={fechalote} />*/}
        <WafoDateTime name="fecha_lote" handleChange />
      </div>

      <WafoFormInput
        type="text"
        name="western"
        label="Western"
        placeholder="Ingrese porcentaje western"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormInput
        type="text"
        name="wichita"
        label="Wichita"
        placeholder="Ingrese porcentaje wichita"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      <WafoFormInput
        type="text"
        name="navajo"
        label="Navajo"
        placeholder="Ingrese porcentaje navajo"
        customClass="col-12 col-md-4"
        validations={{
          required: true,
          maxLength: 254,
        }}
      />

      {!idBatch && (
        <Fragment>
          <div className="col-12 mb-3">
            <div className="form-divider">
              <h5>Líneas de lote</h5>
            </div>
          </div>

          {lines.length === 0 && (
            <FilePicker
              customClass="col-12 col-md-4"
              name="template"
              label="Cargar plantilla"
              validations={{ required: true }}
              handleChange
            />
          )}

          {lines.length > 0 && (
            <LocalTable
              locale="es"
              wrapperClass="table-no-header table-no-pagination"
              tableClass="table table-sm table-striped table-borderless"
              tableWrapperClass="table-wrapper"
              columns={["Línea", "No. Árboles"]}
              rows={lines}
              noRowsMessage="Sin líneas registradas"
              keepPage={false}
              updateTable={f => f}
              columnsConfig={{}}
            />
          )}
        </Fragment>
      )}

      <div className="col-12 mt-3 text-center">
        <button type="submit" form="form-template" className="btn btn-primary">
          <FontAwesomeIcon icon={faSave} className="fa-icon" />
          <span>{idBatch || !!lines.length ? "Guardar cambios" : "Comprobar datos"}</span>
        </button>
      </div>
    </WafoForm>
  );
};

export default FormBatch;
