import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const ActividadesTarjeta = props => {
    const { actividades } = props;

    const activitiesList = React.useMemo(() => {
        console.log(actividades);
        return actividades.map(a => ({ ...a }));
    }, [actividades]);


    return (
        <div className="card separate">
          <div className="card-body">
            <div className="row no-row-margin">
              <div className="col-md-6 col-12">
                <h5>
                  <FontAwesomeIcon icon={faIdCard} className="fa-icon" /> Actividades
                </h5>
              </div>
            </div>
            <div>
              <hr/>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Número</th>
                      <th>Nombre</th>                      
                    </tr>
                  </thead>
                  <tbody>
                  {activitiesList.map(activity => {
                  return (
                    <React.Fragment key={activity.id}>
                      <tr className="">
                        <td>{activity.id}</td>
                        <td>{activity.nombre}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    };

ActividadesTarjeta.defaultProps = {
    actividades: [],
  };
  
export default ActividadesTarjeta;