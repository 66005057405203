import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import DetalleTarjetaTrabajo from "./detalleTarjetaTrabajo/detalleTarjetaTrabajo";
import TarjetasTrabajo from "./tarjetasTrabajo";


const RoutesTarjetasTrabajo = () => {
  const { url } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Redirect exact from={`${url}`} to={`${url}/tarjetas`} />
        <Route exact path={`${url}/tarjetas`} component={TarjetasTrabajo} />
         <Route path={`${url}/detalle/:id`} component={DetalleTarjetaTrabajo} />
      </Switch>
    </div>
  );
};

export default RoutesTarjetasTrabajo;
