import RouterBase from "router/routerBase";
import { AlertProvider } from "utils/context/alertsContext";
import { LoaderProvider } from "utils/context/loaderContext";
import { UIProvider } from "utils/context/uiContext";

function App() {
  return (
    <div className="App" data-testid="App">
      <LoaderProvider>
        <AlertProvider>
          <UIProvider>
            <RouterBase />
          </UIProvider>
        </AlertProvider>
      </LoaderProvider>
    </div>
  );
}

export default App;
